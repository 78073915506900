import React from 'react'
import { graphql } from 'gatsby'
import MDXRenderer from 'gatsby-mdx/mdx-renderer'

import HeroBox from '../components/HeroBox'
import Image from '../components/Image'
import Layout from '../components/Layout'

export default function PostTemplate({ data: { post }, pageContext }) {
  const seo = {
    description: post.frontmatter.summary,
    image: post.frontmatter.image,
    slug: `blog/${post.frontmatter.year}/${post.frontmatter.slug}`,
    title: post.frontmatter.title,
    datePublished: post.frontmatter.date,
    isBlogPost: true,
  }

  const renderPost = post => {
    switch (post.frontmatter.template) {
      case 'Fancy':
        return <FancyPost post={post} />
      case 'Basic':
      default:
        return <BasicPost post={post} />
    }
  }

  // TODO: colorscheme?
  return <Layout seo={seo}>{renderPost(post)}</Layout>
}

const BasicPost = ({ post }) => {
  return (
    <article className="blogpost template-basic">
      <HeroBox>
        <Image data={post.frontmatter.image} alt={post.frontmatter.title} />
      </HeroBox>
      <div className="intro">
        <h1>{post.frontmatter.title}</h1>
        <p>{post.frontmatter.date}</p>
        <p
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.intro || post.frontmatter.summary,
          }}
        />
      </div>
      <MDXRenderer
        components={{
          wrapper: ({ children }) => (
            <React.Fragment>{children}</React.Fragment>
          ),
        }}
      >
        {post.code.body}
      </MDXRenderer>
    </article>
  )
}

// TODO: What could this be?
const FancyPost = ({ post }) => {
  return (
    <article className="blogpost template-fancy">
      <HeroBox>
        <Image data={post.frontmatter.image} alt={post.frontmatter.title} />
      </HeroBox>
      <h2>TODO: What could a fancy post look like?</h2>
    </article>
  )
}

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    post: mdx(frontmatter: { slug: { eq: $slug } }, fileAbsolutePath: { regex: "/content/posts/" }) {
      code {
        body
      }
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        image {
          ...superheroImage
        }
        intro
        summary
        slug
        title
        year: date(formatString: "YYYY")
      }
    }
  }
`
